import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'

import serendipityLogo from '../images/clients/serendipity-ai.jpg'
import bulbLogo from '../images/clients/bulb.jpg'
import timeOutLogo from '../images/clients/time-out.png'
import argosLogo from '../images/clients/argos.png'
import whichLogo from '../images/clients/which.png'
import discoveryLogo from '../images/clients/discovery.jpg'

const Logos = styled.div`
  display: flex;
  justify-content: row;
  flex-wrap: wrap;
  margin: 0;

  @media (min-width: 1024px) {
    margin: 0 -40px;
  }
`

const LogoContainer = styled.div`
  box-sizing: border-box;
  align-self: center;
  margin: 20px 0;
  padding: 0;
  width: 50%;

  @media (min-width: 576px) {
    margin: 40px 0;
    padding: 0 40px;
    width: 33%;
  }
`

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 75%;
  max-height: 100px;

  @media (min-width: 768px) {
    max-height: 140px;
  }
`

const logos = [
  {
    name: 'Serendipity AI',
    src: serendipityLogo,
  },
  {
    name: 'Bulb',
    src: bulbLogo,
  },
  {
    name: 'Which?',
    src: whichLogo,
  },
  {
    name: 'Time Out',
    src: timeOutLogo,
  },
  {
    name: 'Argos',
    src: argosLogo,
  },
  {
    name: 'Discovery',
    src: discoveryLogo,
  },
]

const ClientsPage = () => (
  <Layout title="Clients">
    <Logos>
      {logos.map(logo => (
        <LogoContainer key={logo.name}>
          <Logo src={logo.src} title={logo.name} />
        </LogoContainer>
      ))}
    </Logos>
  </Layout>
)

export default ClientsPage
